import enUS from './en-US';
import esMX from './es-MX';
import esCO from './es-CO';
import ptBR from './pt-BR';

export default {
  'es-MX': esMX,
  'es-CO': esCO,
  'en-US': enUS,
  'pt-BR': ptBR,
};
