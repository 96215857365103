import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TicketPassengerSeat from 'components/purchase/TicketPassengerSeat';
import 'styles/components/purchase/Passengers';
import { Text } from '@reservamos/elements';

const seatType = PropTypes.shape({
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  seat: PropTypes.string,
  isOpenTicket: PropTypes.bool,
});

const propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  departureSeats: PropTypes.arrayOf(seatType).isRequired,
  returnSeats: PropTypes.arrayOf(seatType).isRequired,
  isOpenTicket: PropTypes.bool,
};

const TicketPassenger = ({ name, type, departureSeats, returnSeats, isOpenTicket }) => {
  const { t } = useTranslation('general');

  return (
    <div className="traveler-wrapper">
      <div>
        <Text weight="bold">{name}</Text>
        <Text size="S">{t('category', { context: type })}</Text>
      </div>
      <div>
        {[...departureSeats, ...returnSeats].map(({ from, seat, to, adjacentSeat }) => (
          <TicketPassengerSeat
            key={`${from}-${to} ${seat}`}
            from={from}
            to={to}
            seat={seat}
            isOpenTicket={isOpenTicket}
            adjacentSeat={adjacentSeat}
          />
        ))}
      </div>
    </div>
  );
};
TicketPassenger.propTypes = propTypes;

export default TicketPassenger;
