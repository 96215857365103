import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Currency, Spacing, Text } from '@reservamos/elements';
import { getCurrencyDecimals, getCurrencyPrefix, getCurrencySuffix } from 'utils/currency';
import { useTranslation } from 'react-i18next';
import WalletPrice from './WalletPrice';

/**
 * Renders the NewPriceComponent.
 *
 * @param {Object} props - The component props.
 * @param {number} props.total - The total price.
 * @param {number} props.walletTotal - The total price in wallet points.
 * @param {boolean} props.isHighligth - Indicates if the price should be highlighted.
 * @param {string} props.walletType - The type of points.
 * @param {boolean} props.extraWalletText - Indicates if there is a reward ticket.
 * @param {boolean} props.showWalletTotal - Indicates if the wallet total should be shown.
 * @param {boolean} props.isProvider - Indicates if the price is for a provider.
 * @param {boolean} props.useDiscountCurrency - Indicates if the price should use the discount color.
 * @param {node} props.secondaryPrice - The secondary price.
 * @param {boolean} props.lighterText - Indicates if the text should be lighter.
 * @returns {JSX.Element} The rendered component.
 */
const NewPriceComponent = ({
  total,
  walletTotal,
  isHighligth,
  walletType,
  extraWalletText,
  showWalletTotal,
  isProvider,
  useDiscountCurrency,
  secondaryPrice,
  lighterText,
}) => {
  const { t } = useTranslation('purchase');
  const currencySymbol = getCurrencySuffix();
  const decimals = getCurrencyDecimals();
  const sign = getCurrencyPrefix();

  const defaultColor = isHighligth ? 'primary ' : 'grayStrong';
  const defaultCurrencyColor = useDiscountCurrency ? 'discount' : defaultColor;
  const defaultPriceColor = lighterText ? 'grayLight' : defaultCurrencyColor;

  if (isProvider)
    return (
      <Spacing
        size="S"
        responsiveSize="XXS"
        isResponsive
        alignItems="center"
        mobileAlign="flex-end"
      >
        <Badge type="gradient" alphaBg="M" roundCorners smallPadding>
          <Text size="XS" mobileSize="XXS" color="white">
            {t('label.open_ticket_adult_price')}
          </Text>
        </Badge>
        <Currency
          price={total}
          decimals={decimals}
          color="primary"
          currency={currencySymbol}
          size="XL"
          mobileSize="L"
          weight="bold"
        />
      </Spacing>
    );

  if (showWalletTotal)
    return (
      <WalletPrice
        walletType={walletType}
        walletTotal={walletTotal}
        extraWalletText={extraWalletText}
      />
    );

  return (
    <div className="fade-in animated">
      <Spacing vertical alignItems="flex-end" size="XS">
        <Currency
          price={total}
          color={defaultPriceColor}
          currency={currencySymbol}
          weight="bold"
          size="XL"
          mobileSize="L"
          decimals={decimals}
          sign={sign}
        />
        {secondaryPrice}
      </Spacing>
    </div>
  );
};

NewPriceComponent.propTypes = {
  total: PropTypes.number,
  isHighligth: PropTypes.bool,
  extraWalletText: PropTypes.bool,
  walletTotal: PropTypes.number,
  walletType: PropTypes.string,
  showWalletTotal: PropTypes.string,
  isProvider: PropTypes.bool,
  useDiscountCurrency: PropTypes.bool,
  secondaryPrice: PropTypes.node,
  lighterText: PropTypes.bool,
};

export default NewPriceComponent;
