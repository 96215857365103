import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CurrentLang, setLang } from 'utils/lang';
import './LngButton.scss';
import { Icon } from '@reservamos/elements';
import flagUsa from 'images/languages/flag-usa.svg';
import flagMex from 'images/languages/flag-mex.svg';
import flagCol from 'images/languages/flag-col.svg';

const LanguageCodes = [
  {
    flag: flagUsa,
    title: 'Switch to English site',
    lng: 'en-US',
  },
  {
    flag: flagMex,
    title: 'Cambiar sitio a Español',
    lng: 'es-MX',
  },
  {
    flag: flagCol,
    title: 'Cambiar sitio a Colombia',
    lng: 'es-CO',
  },
];

const LngButton = () => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { i18n } = useTranslation();

  if (!features.ALLOW_TO_CHANGE_LANGUAGE) return null;

  const currentLng = CurrentLang();
  const availableLanguages = env.lang.available;
  const nextLng = availableLanguages.find((lang) => lang !== currentLng);
  const LanguageCode = LanguageCodes.find((langCode) => langCode.lng === nextLng);

  const ChangeLanguage = () => {
    setLang(i18n, LanguageCode.lng);
    window.location.reload();
  };

  return (
    <button className="flag-button" onClick={() => ChangeLanguage()} title={LanguageCode.title}>
      <Icon type={LanguageCode.flag} />
    </button>
  );
};

export default LngButton;
