import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { fieldRenderer } from 'utils/formRenderers';
import { validateTaxpayerId } from '@/actions/purchase';
import { useDispatch } from 'react-redux';
import { When } from 'react-if';
import useForm from 'hooks/store/useForm';
import usePurchase from 'hooks/store/usePurchase';
import LabelSwitch from '../../../ui/atoms/LabelSwitch';

/**
 * TaxpayerIdInput component
 *
 * This component renders a form section for inputting taxpayer information.
 * It includes a checkbox to indicate if an invoice is needed, and conditional
 * fields for RUC (taxpayer ID) and legal name.
 *
 * @returns {React.Component} The TaxpayerIdInput component
 */
function TaxpayerIdInput() {
  const { t } = useTranslation('passengers');
  const dispatch = useDispatch();
  const { purchaser } = useForm();
  const { fetchingTaxpayerId } = usePurchase();

  /**
   * Handles changes to the document ID input.
   * Dispatches an action to validate the taxpayer ID.
   *
   * @param {string} newValue - The new value of the document ID input.
   */
  const handleDocumentIdChange = (newValue) => {
    dispatch(validateTaxpayerId(newValue));
  };

  const { invoice } = purchaser.values;

  return (
    <>
      <Field
        name="invoice"
        id="invoice"
        type="checkbox"
        component={({ input }) => (
          <LabelSwitch
            checked={!!input.value}
            onChange={input.onChange}
            id="need-invoice"
            label={t('label.need_invoice')}
          />
        )}
        defaultChecked={false}
      />
      <When condition={invoice}>
        <div className="flex gap-3">
          <Field
            autoComplete="do-not-autofill"
            type="text"
            name="taxpayerId"
            id="taxpayerId"
            placeholder={t('label.taxpayer_id')}
            maxLength={11}
            component={fieldRenderer}
            onChange={(event, newValue) => handleDocumentIdChange(newValue)}
            isDisabled={fetchingTaxpayerId}
          />
          <Field
            autoComplete="do-not-autofill"
            type="text"
            name="legalName"
            id="legal-name"
            placeholder={t('label.legal_name')}
            component={fieldRenderer}
            isDisabled
          />
        </div>
      </When>
    </>
  );
}

export default TaxpayerIdInput;
