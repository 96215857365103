// return an object collection of the seats for a bus layout
const getSeats = (layout, tripSlug) => {
  const seats = {};
  const categories = [];
  layout
    .flat(2)
    .filter((item) => item.number)
    .forEach((item) => {
      /**
       * Adding the tripSlug to the seat object helps us identify the seats by trip when we have connections.
       * When rendering, selecting or deselecting a seat, the whole seat object travels around the app state.
       * Setting the tripSlug here is the best place to do it, so we only have to do it once.
       */
      seats[item.number] = { ...item, tripSlug };
      if (categories.indexOf(item.category) === -1) categories.push(item.category);
    });
  seats.categories = categories;
  return seats;
};

export default getSeats;
